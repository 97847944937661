@import "plugins/bootstrap/bootstrap";
//@import "../js/plugins/lightbox/lightbox.scss";

@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-Light.eot');
	src: url('fonts/Rosario/Rosario-Light.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-Light.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-Light.woff') format('woff'),
		 url('fonts/Rosario/Rosario-Light.ttf') format('truetype');
	font-style: normal;
	font-weight: 300;
}
@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-LightItalic.eot');
	src: url('fonts/Rosario/Rosario-LightItalic.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-LightItalic.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-LightItalic.woff') format('woff'),
		 url('fonts/Rosario/Rosario-LightItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: 300;
}
@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-Regular.eot');
	src: url('fonts/Rosario/Rosario-Regular.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-Regular.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-Regular.woff') format('woff'),
		 url('fonts/Rosario/Rosario-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-Italic.eot');
	src: url('fonts/Rosario/Rosario-Italic.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-Italic.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-Italic.woff') format('woff'),
		 url('fonts/Rosario/Rosario-Italic.ttf') format('truetype');
	font-style: italic;
	font-weight: 400;
}
@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-SemiBold.eot');
	src: url('fonts/Rosario/Rosario-SemiBold.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-SemiBold.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-SemiBold.woff') format('woff'),
		 url('fonts/Rosario/Rosario-SemiBold.ttf') format('truetype');
	font-style: normal;
	font-weight: 600;
}
@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-SemiBoldItalic.eot');
	src: url('fonts/Rosario/Rosario-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-SemiBoldItalic.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-SemiBoldItalic.woff') format('woff'),
		 url('fonts/Rosario/Rosario-SemiBoldItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: 600;
}
@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-Bold.eot');
	src: url('fonts/Rosario/Rosario-Bold.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-Bold.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-Bold.woff') format('woff'),
		 url('fonts/Rosario/Rosario-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: 700;
}
@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-BoldItalic.eot');
	src: url('fonts/Rosario/Rosario-BoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('fonts/Rosario/Rosario-BoldItalic.woff2') format('woff2'),
		 url('fonts/Rosario/Rosario-BoldItalic.woff') format('woff'),
		 url('fonts/Rosario/Rosario-BoldItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: 700;
}

//$special_font:'Rosario', sans-serif;
//$main_font:'DM Sans', sans-serif;

$main_font:'Nunito Sans', sans-serif;
$special_font:$main_font;

$header_color:#041437;
$font_color:#4f5769;

$theme_color1: $header_color;
$theme_color2:#f3f3f3;
$accent_color1:#eac435;
$accent_color2:#081d4c;
$white:#ffffff;
$black:#000000;
$border_color:#e3e4e8;

@mixin shadow {
    -webkit-box-shadow: 0px 0px 25px 11px rgba(245,245,244,1);
    -moz-box-shadow: 0px 0px 25px 11px rgba(245,245,244,1);
    box-shadow: 0px 0px 25px 11px rgba(245,245,244,1);
}

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

@mixin vertical {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@mixin bg_default_pos {
    background-repeat: no-repeat;
    background-position: center;
}

@mixin gradient1 {
    background: rgba(180,157,53,1);
    background: -moz-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(180,157,53,1)), color-stop(35%, rgba(180,157,53,1)), color-stop(100%, rgba(234,195,53,1)));
    background: -webkit-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%);
    background: -o-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%);
    background: -ms-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%);
    background: linear-gradient(to right, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b49d35', endColorstr='#eac335', GradientType=1 );
}

@mixin gradient2 {
    background: rgba(4,20,55,1);
background: -moz-linear-gradient(45deg, rgba(4,20,55,1) 0%, rgba(4,20,55,1) 46%, rgba(16,43,107,1) 100%);
background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(4,20,55,1)), color-stop(46%, rgba(4,20,55,1)), color-stop(100%, rgba(16,43,107,1)));
background: -webkit-linear-gradient(45deg, rgba(4,20,55,1) 0%, rgba(4,20,55,1) 46%, rgba(16,43,107,1) 100%);
background: -o-linear-gradient(45deg, rgba(4,20,55,1) 0%, rgba(4,20,55,1) 46%, rgba(16,43,107,1) 100%);
background: -ms-linear-gradient(45deg, rgba(4,20,55,1) 0%, rgba(4,20,55,1) 46%, rgba(16,43,107,1) 100%);
background: linear-gradient(45deg, rgba(4,20,55,1) 0%, rgba(4,20,55,1) 46%, rgba(16,43,107,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#041437', endColorstr='#102b6b', GradientType=1 );
}

body{
    font-family: $main_font;
    h1{
        font-size: 75px;
        line-height: 90px;
        @media only screen and (max-width: 1600px) {
            font-size: 50px;
            line-height: 65px;
        }
        @media only screen and (max-width: 1200px) {
            font-size:31px;
            line-height: 46px;
        }
    }
    img{
        max-width: 100%;
        height: auto;
    }
    h1,h2,h3,h4,h5,h6,p,a,ul,ol{
        margin: 0;
        font-weight: 400;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none !important;
        &:hover{
            text-decoration: none !important;
        }
    }
}

.page{
    overflow: hidden;
    @include anim;
    opacity: 0;
}



.default_font{
    font-size: 15px;
    line-height: 30px;
    @media only screen and (max-width: 1500px) {
        font-size:13px;
        line-height: 28px;
    }
    h1{
        font-size: 43px;
        line-height: 53px;
        @media only screen and (max-width: 1500px) {
            font-size: 37px;
            line-height: 47px;
        }
    }
    h2{
        font-size: 37px;
        line-height: 47px;
        @media only screen and (max-width: 1500px) {
            font-size: 31px;
            line-height: 41px;
        }
    }
    h3{
        font-size: 31px;
        line-height: 41px;
        @media only screen and (max-width: 1500px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 1500px) {
            font-size: 21px;
            line-height: 31px;
        }
    }
    h5{
        font-size: 21px;
        line-height: 31px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
            line-height: 28px;
        }
    }
    h6{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 26px;
        }
    }
    ul{
        list-style: disc;
        padding-left: 18px;
    }
    ol{
        padding-left: 18px;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: $special_font;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 20px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 15px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.more_btn1{
    display: inline-block;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: $white;
    background: $accent_color2;
    padding: 0 40px;
    position: relative;
    @include anim;
    &:hover{
        color: $accent_color1;
        &:after{
            right: -37px;
        }
    }
    &:after{
        content: '';
        @include anim;
        position: absolute;
        top: 37%;
        right: -17px;
        height: 12px;
        width: 37px;
        @include bg_default_pos;
        background-image: url('./../img/more_arr.png');
    }
}

.more_btn2{
    display: inline-block;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: $white;
    background: $accent_color2;
    padding: 0 50px;
    position: relative;
    @include anim;
    &:hover{
        color: $accent_color1;
        &:after{
            left: -37px;
        }
    }
    &:after{
        content: '';
        @include anim;
        position: absolute;
        top: 37%;
        left: -17px;
        height: 12px;
        width: 37px;
        @include bg_default_pos;
        background-image: url('./../img/more_arr.png');
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }
}

.more_btn_popup{
    display: inline-block;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: $white;
    background: $accent_color2;
    padding: 0 50px;
    position: relative;
    color: $accent_color1;
    @include anim;
    &:hover{
        color: $accent_color1;
        &:after{
            left: -37px;
        }
    }
    &:after{
        content: '';
        @include anim;
        position: absolute;
        top: 37%;
        left: -17px;
        height: 12px;
        width: 37px;
        @include bg_default_pos;
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }
}

.header{
    padding: 23px 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: $white;
    @include anim;
    @media only screen and (max-width: 1500px) {
        padding: 5px 0;
    }
    @media only screen and (max-width: 1200px) {
        padding:10px 0 5px 0;
    }
    &.smaller{
        padding: 5px 0;
        div.logo_box, .social{
            img{
                max-width: 50%;
            }
        }
        .menu_box{
            padding-top: 0;
            @media only screen and (max-width: 1500px) {
                padding-top: 8px;
            }
        }
        .logo_box{
            @media only screen and (max-width: 1500px) {
                img{
                    max-width: 80% !important;
                }
            }
            @media only screen and (max-width: 991px) {
                img{
                    max-width: 100px !important;;
                }
            }
        }
    }
    @media only screen and (max-width: 991px) {
        padding: 15px 0;
    }
    div{
        &.logo_box{
            position: relative;
            img{
                @include anim;
            }
            @media only screen and (max-width: 991px) {
                img{
                    max-width: 100px !important;
                }
                div.rwd_btn{
                    width: 30px;
                    position: absolute;
                    top:13px;
                    right: 0;
                    > div{
                        width: 100%;
                        height: 3px;
                        margin-bottom: 3px;
                        background: $accent_color2;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &.menu_box{
            text-align: right;
            padding-top: 17px;
            @include anim;
            @media only screen and (max-width: 991px) {
                text-align: left;
                padding-top: 15px;
                display: none;
            }
            ul{
                li{
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        vertical-align: top;
                    }
                    &.menu{
                        @media only screen and (max-width: 991px) {
                            width: calc(100% - 50px);
                        }
                        nav{
                            ul{
                                li{
                                    display: inline-block;
                                    vertical-align: top;
                                    margin-right: 33px;
                                    position: relative;
                                    @media only screen and (max-width: 1500px) {
                                        margin-right: 16px;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        margin-right: 11px;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        margin-right: 0;
                                        width: 100%;
                                        text-align: left;
                                    }
                                    &:hover{
                                        div{
                                            &:after{
                                                width: 22px;
                                            }
                                        }
                                    }
                                    div{
                                        padding-left: 20px;
                                        @media only screen and (max-width: 1200px) {
                                            padding-left: 10px;
                                        }
                                        &.on{
                                            &:after{
                                                width: 22px;
                                            }
                                        }
                                        &:before{
                                            content: '';
                                            width: 22px;
                                            border-top: 2px solid $accent_color1;
                                            position: absolute;
                                            left: 0;
                                            top: 40%;
                                            @media only screen and (max-width: 1200px) {
                                                width: 15px;
                                            }
                                        }
                                        &:after{
                                            content: '';
                                            width: 0;
                                            border-top: 2px solid $header_color;
                                            position: absolute;
                                            left: 0;
                                            top: 40%;
                                            @include anim;
                                        }
                                        a{
                                            padding-left: 15px;
                                            font-size: 15px;
                                            line-height: 15px;
                                            color: $header_color;
                                            display: inline-block;
                                            font-weight: 700;
                                            font-family: $special_font;
                                            @media only screen and (max-width: 1200px) {
                                                font-size: 13px;
                                                line-height: 13px;
                                            }
                                            @media only screen and (max-width: 991px) {
                                                line-height: 25px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.social{
                        ul{
                            @media only screen and (max-width: 991px) {
                                text-align: right;
                            }
                            li{
                                display: inline-block;
                                vertical-align: top;
                                img{
                                    max-width: 40px;
                                }
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            width: 50px;
                        }
                        img{
                            @include anim;
                            @media only screen and (max-width: 1500px) {
                                max-width: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.slider{
    position: relative;
    > ul{
        @include fill;
        background: $black;
        > li{
            @include fill;
            @include bg_default_pos;
            background-size: cover;
            @media only screen and (max-width: 991px) {
                background-position: right 40% top;
                @include vertical;
            }
            div{
                &.slider_cover{
                    @include fill;
                    background-repeat: no-repeat;
                    background-position: left top;

                    background-size: 66% auto;
                    @media only screen and (max-width: 991px) {
                        background-image: url('./../img/slider_cover.png');
                        background-size: cover;
                    }
                }
                &.slider_content{
                    color: $white;
                    padding-top: 90px;
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    .default_font{
                        font-size: 18px;
                        @media only screen and (max-width: 991px) {
                            font-size: 15px;
                        }
                    }
                    p{
                        text-shadow: 0px 0px 2px rgba(0,0,0,0.8);
                    }
                    .desc_holder{
                        position: relative;
                        z-index: 5;
                    }
                    @media only screen and (max-width: 991px) {
                        padding-top: 20px;
                    }
                    header.slide_header{
                        margin-bottom: 30px;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 20px;
                        }
                        .over_header{
                            font-size: 18px;
                            line-height: 28px;
                            padding-left: 87px;
                            position: relative;
                            font-weight: 700;
                            margin-bottom: 30px;
                            padding-bottom: 15px;
                            @media only screen and (max-width: 1500px) {
                                padding-bottom: 10px;
                            }
                            @media only screen and (max-width: 991px) {
                              padding-bottom: 2px;
                            }
                            &:before{
                                content: '';
                                width: 87px;
                                border-top: 2px solid $accent_color1;
                                position: absolute;
                                left: 0;
                                top: 43%;

                                @media only screen and (max-width: 991px) {
                                    right: 0;
                                    margin-left: auto;
                                    margin-right: auto;
                                }
                            }
                            p{
                                padding-left: 22px;
                                font-weight: inherit;
                            }
                        }
                        font-family: $special_font;
                    }
                    .desc{
                        margin-bottom: 30px;
                        font-size: 15px;
                        line-height: 30px;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
    .slider_nav{
        text-align: right;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 20px;
        @media only screen and (max-width: 991px) {
            bottom: 0;
        }
        ul{
            li{
               display: inline-block;
               vertical-align: middle;
               @media only screen and (max-width: 991px) {
                width: 50%;
                }
                &.dots{
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    ul{
                        li{
                            margin-right: 22px;
                            border-radius: 50%;
                            cursor: pointer;
                            width: 10px;
                            height: 0;
                            padding-bottom: 10px;
                            background: rgba(255, 255, 255, 0.5);
                            @include anim;
                            &.on{
                                background: $white;
                            }
                        }
                    }
                }
                &.arrs{
                    div{
                        display: inline-block;
                        vertical-align: top;
                        margin-right:22px;
                        cursor: pointer;
                        &:last-child{
                            margin-right: 0;
                        }
                   }
                }
            }
        }
    }
}

.home_search{
    background: $theme_color1;
    color: #7585aa;
    padding: 50px 0;
    @include bg_default_pos;
    background-size: 100%;
    background-image: url('./../img/home_search_bg.jpg');
    @media only screen and (max-width: 1500px) {
        padding: 25px 0;
    }
    .desc{
        font-size: 15px;
        line-height: 30px;
        @media only screen and (max-width: 991px) {
            text-align: center;
            margin-bottom: 50px;
        }
        header{
            margin-bottom: 15px;
            padding-top: 15px;
            @media only screen and (max-width: 1500px) {
                padding-top: 5px;
            }
            h1,h2,h3,h4,h5,h6{
                color:$white;
            }
        }
    }
}

.search{
    padding: 7px 20px 7px 40px;
    @media only screen and (max-width: 991px) {
        padding: 7px 0px 7px 15px;
    }
    @include gradient1;
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $header_color;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: $header_color;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: $header_color;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: $header_color;
      }
    input{
        background: transparent;
        display: inline-block;
        vertical-align: middle;
        outline: none;
        border: none;
        &.search_input{
            font-weight: 700;
            width: calc(100% - 72px);
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            font-size: 15px;
            height: 34px;
            color:$header_color;
        }
        &.search_submit{
            background-size: contain;
            width: 72px;
            height: 0;
            padding-bottom: 72px;
            @include bg_default_pos;
            background-image: url('./../img/search_icon.png');
            @include anim;
            &:hover{
                transform: scale(1.05);
            }
        }
    }
}

.section_header{
    padding: 50px 0;
    color: $font_color;
    @media only screen and (max-width: 1200px) {
        padding: 30px 0;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    .default_font{
        color: $header_color;
        font-family: $special_font;
    }
}

.short_about{
    padding: 50px 0;
    position: relative;
    @media only screen and (max-width: 991px) {
        padding: 50px 0 0 0;
    }
    div{
        &.about_box{
            @include shadow;
            padding-left: 70px;
            padding-bottom: 50px;
            padding-right: 36%;
            @media only screen and (max-width: 991px) {
                padding-left: 15px;
                padding-right: 15px;
                margin-bottom: 30px;
                padding-bottom: 30px;
            }
            > div{
                &.default_font{
                    color:$font_color;
                    padding-bottom: 80px;
                    background-position: right bottom;
                    background-repeat: no-repeat;
                    background-image: url('./../img/quote1_bottom.png');
                    position: relative;
                    padding-top: 10px;
                    .autograph{
                        position: absolute;
                        left: 0;
                        bottom: 15px;
                        width: 88%;
                        height: 63px;
                        img{
                            max-width: auto;
                            width:auto;
                            height:100%;
                        }
                    }
                    &:before{
                        bottom: 100%;
                        left: -28px;
                        width: 33px;
                        height: 23px;
                        position: absolute;
                        content: '';
                        @include bg_default_pos;
                        background-image: url('./../img/quote1_top.png');
                        @media only screen and (max-width: 991px) {
                            left: 3px;
                        }
                    }
                }
            }
        }
        &.side_image{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 36%;
            @media only screen and (max-width: 991px) {
                display: none;
            }
            div{
                @include bg_default_pos;
                height: 304px;
                background-size: cover;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%
            }
        }
        &.image{
            width: 46%;
            height: 620px;
            position: absolute;
            bottom: 0;
            right: 0;
            @media only screen and (max-width: 991px) {
                width: auto;
                position: relative;
                height: auto;
                padding: 0 15px;
            }
            img{
                max-width: auto;
                width: auto;
                height: 125%;
                position: absolute;
                bottom: 0;
                left: 0;
                @media only screen and (max-width: 991px) {
                    height: auto;
                    position: relative;
                }
            }
        }
    }
}

.tips{
    padding:0 0 50px 0;
    background-color: $theme_color2;
    border-bottom: 1px solid $border_color;
    &.with_bg{
        border: none;
        background-color: $white;
        background-image:url('./../img/tips_bg.png');
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .row{
        margin: 0 -20px;
        > div{
            padding: 0 20px;
        }
    }
    div.tip_box{
        height: 410px;
        width: 100%;
        @include bg_default_pos;
        background-size: cover;
        overflow: hidden;
        position: relative;
        margin-bottom: 40px;
        @media only screen and (max-width: 991px) {
            min-height: 360px;
            height: auto;
        }
        .cover{
            opacity: 0.8;
            @include fill;
            background: rgba(21,26,35,0);
            background: -moz-linear-gradient(top, rgba(21,26,35,0) 30%, rgba(95,102,113,1) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(30%, rgba(21,26,35,0)), color-stop(100%, rgba(95,102,113,1)));
            background: -webkit-linear-gradient(top, rgba(21,26,35,0) 30%, rgba(95,102,113,1) 100%);
            background: -o-linear-gradient(top, rgba(21,26,35,0) 30%, rgba(95,102,113,1) 100%);
            background: -ms-linear-gradient(top, rgba(21,26,35,0) 30%, rgba(95,102,113,1) 100%);
            background: linear-gradient(to bottom, rgba(21,26,35,0) 30%, rgba(95,102,113,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151a23', endColorstr='#5f6671', GradientType=0 );
        }
        &:hover{
            .desc{
                top: 0;
                background: $white;
                .tip_header{
                    div.default_font{
                        color: $header_color;
                    }
                }
            }
        }
        .desc{
            @include anim;
            top: 73%;
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            padding: 40px;
            @include anim;
            z-index: 2;
            @media only screen and (max-width: 991px) {
                top: 0;
                background: rgba(255,255,255,0.8);
                padding: 15px;
            }
            .tip_header{
                font-family: $special_font;
                margin-bottom: 30px;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 37px;
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 20px;
                }
                div.default_font{
                    position: relative;
                    padding-left: 72px;
                    color: $white;
                    text-transform: uppercase;
                    @include anim;

                    @media only screen and (min-width: 991px) {
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }
                    @media only screen and (max-width: 991px) {
                        color: $header_color;
                        text-align: center;
                        padding-left: 0;
                        h3{
                            font-size: 17px;
                        }
                    }
                    &:before{
                        content: '';
                        height: 3px;
                        width: 72px;
                        @include gradient1;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        @media only screen and (max-width: 991px) {
                           display: none;
                        }
                    }
                    h1,h2,h3,h4,h5,h6{
                        padding-left: 24px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                        }
                    }
                }
            }
            .border_box{
                padding:20px 30px;
                border: 1px solid $accent_color1;
                color: $font_color;
                font-size: 12.5px;
                line-height: 28px;
                color: $header_color;
                height: 266px;
                div{
                    display: -webkit-box;
                    -webkit-line-clamp: 8;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.warranty{
    background: $white;
    border-bottom: 1px solid $border_color;

    .diagram{
        padding-bottom: 50px;
        @include bg_default_pos;
        background-image: url('./../img/diagram_bg.png');
        background-size: contain;
        @media only screen and (max-width: 1500px) {
            background-size: auto 80%;
            .first_desc{
                margin-bottom: - 100px;
            }
        }
        @media only screen and (max-width: 991px) {
            background-image: none !important;
            padding-bottom: 30px;
            .first_desc{
                margin-bottom: 0;
            }
        }
        .diagram_desc{
            padding: 0 20px;
            @media only screen and (max-width: 991px) {
                padding: 0;
                text-align: center;
                margin-bottom: 20px;
            }
            header{
                font-family: $special_font;
                color: $header_color;
                div{
                    display: inline-block;
                    vertical-align: middle;
                    &.icon{
                        width: 116px;
                        margin: 0 auto 0 auto;
                    }
                    &.desc{
                        width: calc(100% - 116px);
                        font-size: 12px;
                        line-height: 24px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        h3{
                            font-size: 18px;
                            line-height: 28px;
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
            .desc{
                font-size: 12.5px;
                line-height: 24px;
                color: $header_color;

            }
            > .desc{
                padding: 0 25px;
            }
        }
        .margin{
            padding-bottom: 100px;
            margin-bottom: 100px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
        .margin2{
          //  margin-bottom: 30px;
        }
    }
}

.features{
    padding-bottom: 100px;
    @media only screen and (max-width: 991px) {
        padding-bottom: 30px;
    }
    .section_header{
        font-size:18px;
        line-height: 29px;
        color: $font_color;
        .default_font{
            margin-bottom: 15px;
        }
    }
}

.faq{
    padding-bottom: 100px;
    .toggles_list{
        ul{
            li{
                margin-bottom: 30px;
            }
        }
    }
    .banner{
        height: 180px;
        display: table;
        width: 100%;
        @include bg_default_pos;
        background-size: cover;
        background-color: $accent_color1;
        margin-bottom: 60px;
        padding: 0 70px;
        @media only screen and (max-width: 991px) {
            text-align: center;
            height: auto;
            display: block;
            padding: 0 30px 30px 30px;
        }
        div{
            display: table-cell;
            vertical-align: middle;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            &.desc{
               width: 70%;
               @media only screen and (max-width: 991px) {
                width: 100%;
               }
              .section_header{
                  div.default_font{
                      h3{
                          color: $white;
                      }
                  }
              }
            }
            &.btn_holder{
                width: 30%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
}

.toggles_list{
    ul{
        li{
            margin-bottom: 50px;
            @media only screen and (max-width: 991px) {
                margin-bottom: 20px;
            }
            &:last-child{
                margin-bottom: 0;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 20px;
                }
            }
            &.open{
                .toggle_box{
                    div{
                        &.toggle_top{
                            background-image: url('./../img/minus_icon.png');
                        }
                    }
                }
            }
            .toggle_box{
                cursor: pointer;
                border: 1px solid $border_color;
                padding: 21px;
                div{
                    &.toggle_top{
                        padding-right: 21px;
                        font-family: $special_font;
                        color: $header_color;
                        background-position: right top 5px;
                        background-repeat: no-repeat;
                        background-image: url('./../img/plus_icon.png');
                        @include anim;

                    }
                    &.toggle_bottom{
                        color: $font_color;
                        display: none;
                        padding-top: 21px;
                    }
                }
                .default_font{
                    line-height: 21px;
                }
            }
        }
    }
}

.short_find_counselor{
    background: $theme_color1;
    div{
        &.form_box{
            color: #7585aa;
            padding: 50px 0;
            background-position: center bottom;
            background-repeat: no-repeat;
            background-image: url('./../img/short_search_bg.png');
            .desc{
                margin-bottom: 50px;
                font-size: 15px;
                line-height: 30px;
                header{
                    margin-bottom: 15px;
                    padding-top: 15px;
                    h1,h2,h3,h4,h5,h6{
                        color:$white;
                    }
                }
            }
        }
        &.step_box{
            padding-bottom: 50px;
           h1,h2,h3,h4,h5,h6,p{
            color: $white;
           }
           .step_list{
               ol{
                counter-reset: item;
                margin-left: 0;
                padding-left: 0;
                   li{
                       display: inline-block;
                       vertical-align: top;
                       padding-left: 50px;
                       position: relative;
                       width: 17%;
                       margin-right: 10%;
                       @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        }
                       &:before{
                           position: absolute;
                           top: -6px;
                           left: 0;
                            display: inline-block;
                            content: counter(item) "";
                            counter-increment: item;
                            width: 34px;
                            height: 34px;
                            line-height: 34px;
                            font-size:13px;
                            font-weight: 700;
                            color: $white;
                            text-align: center;
                            border-radius: 50%;
                            background: rgba(0,0,13,1);
                            background: -moz-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0,0,13,1)), color-stop(100%, rgba(15,56,144,1)));
                            background: -webkit-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                            background: -o-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                            background: -ms-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                            background: linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000d', endColorstr='#0f3890', GradientType=1 );
                       }
                       header{
                           text-transform: uppercase;
                           h3{
                            font-size: 16px;
                            line-height: 25px;
                           }
                       }
                       font-size:12.5px;
                       line-height: 23px;
                       &:last-child{
                        margin-right: 0;
                       }
                   }
               }
           }
        }
    }
}

.reviews{
    padding: 100px 0;
    border-bottom: 1px solid $border_color;
    overflow: hidden;
    position: relative;
    @media only screen and (max-width: 991px) {
        padding: 50px 0;
    }
    &.simple_list{
        overflow: visible;
        .carousel_content{
            width: 100%;
            margin-left: 0;
            ul{
                margin: 0 -20px;
                white-space: normal;
                 li{
                    margin-right: 0;
                    padding: 0 20px;
                    width: 22%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .carousel_content{
        .arr{
            position: absolute;
            top: 0;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            @include vertical;
            opacity: 0;
            visibility: hidden;
            img{
                display: block;
                cursor: pointer;
            }
            &.arr_left{
                left: 0;
                img{
                    margin-left: auto;
                }
            }
            &.arr_right{
                right: 0;
            }
        }
        text-align: center;
        width: 102%;
        margin-left: -8%;
        @media only screen and (max-width: 991px) {
            margin-left: 0;
            width: 100%;
        }
        ul{
            position: relative;
            white-space: nowrap;
            li{
                cursor: pointer;
                display: inline-block;
                vertical-align: top;
                width:18%;
                white-space: normal;
                margin-right: 6%;
                background-repeat: no-repeat;
                background-position: left top 100px;
                background-image: url('./../img/quote2_top.png');
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding: 15px;
                }
                &:hover{
                    div{
                        &.image{
                            border-radius: 0;
                            transform: scale(1.1);
                        }
                    }
                }
                div{
                    &.image{
                        border: 2px solid $white;
                        border-radius: 50%;
                        width: 150px;
                        height: 0;
                        padding-bottom: 150px;
                        position: relative;
                        margin: 0 auto 30px auto;
                        overflow: hidden;
                        background: $header_color;
                        -webkit-box-shadow: 0px 0px 48px -12px rgba(48,54,111,0.6);
                        -moz-box-shadow: 0px 0px 48px -12px rgba(48,54,111,0.6);
                        box-shadow: 0px 0px 48px -12px rgba(48,54,111,0.6);
                        @include anim;
                       > div{
                        @include fill;
                        overflow: hidden;
                        > div{
                            opacity: 0.8;
                            filter: saturate(4);
                            @include fill;
                            @include bg_default_pos;
                            background-size: cover;
                        }
                       }
                    }
                    &.desc{
                        text-align: center;
                        .more_btn1{
                            margin-top: -50px;
                        }
                        header{
                            font-size:11px;
                            color: #a28825;
                            margin-bottom: 30px;
                            h3{
                                font-size: 21px;
                                line-height: 31px;
                                color: $header_color;
                                font-family: $special_font;
                                font-weight: 700;
                                margin-bottom: 10px;
                            }
                        }
                        .desc{
                            color:$font_color;
                            font-size: 13px;
                            line-height: 30px;
                            padding-bottom: 55px;
                            background-repeat: no-repeat;
                            background-position: right bottom;
                            background-image: url('./../img/quote2_bottom.png');
                            height: 145px;
                            p{
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .side_left, .side_right{
        padding: 0 15px !important;
    }
    .text-right{
        text-align: center;
    }
}

.news_newsletter{
    padding: 100px 0;
    display: flex;
    @media only screen and (max-width: 991px) {
        padding: 50px 0 0 0;
        display: block;
        .more_btn1{
            margin-top: 20px;
        }
    }
    > div{
        display: inline-block;
        vertical-align: top;
        .news_list{
            ul{
                li{
                    width: 45%;
                    margin-right:10%;
                    margin-bottom: 10%;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                        width: 100%;
                        margin-right: 0;
                    }
                    &:nth-child(2n){
                        margin-right: 0;
                    }
                    &:nth-child(4n){
                        margin-bottom: 0;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                        }
                    }
                    &:nth-child(4n - 1){
                        margin-bottom: 0;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
        &.side_left{
            width: 45%;
            margin-right: 5%;
            @media only screen and (max-width: 991px) {
                margin-right: 0;
                width: 100%;
            }
            .section_header{
                padding-top: 0;
            }
        }
        &.side_right{
            width: 50%;
            background-color: $theme_color2;
            border-top: 1px solid $accent_color1;
            border-left: 1px solid $accent_color1;
            border-bottom: 1px solid $accent_color1;
            background-position: right top;
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-image: url('./../img/newsletter_img.png');
            @media only screen and (max-width: 991px) {
                width: 100%;
                background-size: cover;
                border-left: none;
            }
            .newsletter{
                padding-top: 70px;
                padding-left: 70px;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    padding-top: 50px;
                    text-align: center;
                }
                header{
                    font-size: 18px;
                    line-height: 28px;
                    color: $header_color;
                    font-family: $special_font;
                    margin-bottom: 50px;
                    h3{
                        font-size: 25px;
                        line-height: 35px;
                    }
                }
            }
        }
    }
}

.sites{
    text-align: center;
    padding-top: 50px;
    border-top: 1px solid $border_color;
    @media only screen and (max-width: 991px) {
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $border_color;
        margin-bottom: 30px;
    }
    ul{
        li{
            margin-right: 15px;
            display: inline-block;
            vertical-align: top;
            &:last-child{
                margin-right: 0;
            }
            a{
                font-size: 20px;
                font-weight: 700;
                line-height: 20px;
                color: $font_color;
                &.active{
                    color: $accent_color1;
                }
            }
        }
    }
}

.default_form{
    padding: 30px 40px;
    background: $white;
    border: 1px solid $accent_color1;
    @media only screen and (max-width: 991px) {
        padding: 15px;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #394569;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #394569;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #394569;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #394569;
      }
    .holder{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    input{
        outline: none;
        &.n_input{
            height: 40px;
            width: 100%;
            font-size: 13px;
            color: $font_color;
            border: none;
            border-bottom: 1px solid $border_color;
            font-weight: 500;
        }
        &.n_submit{
            margin-right: -20px;
            border: none;
            font-size: 11px;
            padding-bottom: 20px;
            color: #041437;
            text-transform: uppercase;
            height: 77px;
            font-weight: 700;
            background-repeat: no-repeat;
            background-position: right center;
            padding: 0 78px 7px 0;
            background-image: url(./../img/news_letter_submit.png);
            background-color: transparent;
        }
    }
    textarea{
        padding-top: 10px;
            outline: none;
            resize: none;
            min-height: 70px;
            width: 100%;
            font-size: 13px;
            color: $font_color;
            border: none;
            border-bottom: 1px solid $border_color;
            font-weight: 500;
    }
}

.news_list{
    &.side{
        ul{
            li{
                width: 100%;
                margin-right: 0;
                margin-bottom: 50px;
            }
        }
    }
    ul{
        li{
            display: inline-block;
            vertical-align: top;
            &:hover{
                a{
                    h3{
                        color:$accent_color1;
                    }
                }
            }
            article{
                div.image{
                    height: 190px;
                    @include bg_default_pos;
                    background-size: contain;
                    width: 100%;
                    margin-bottom: 20px;
                    @media only screen and (max-width: 991px) {
                        height: 0;
                        padding-bottom: 100%;
                    }
                }
                div.info{
                    margin-bottom: 20px;
                    > div{
                        display: inline-block;
                        vertical-align: middle;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                    }
                    div.tag{
                        margin-right: 20px;
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            margin-bottom: 5px;
                        }
                        p{
                            background: $accent_color1;
                            color:$header_color;
                            display: inline-block;
                            text-transform: uppercase;
                            font-weight: 700;
                            font-size:9px;
                            line-height:15px;
                            height: 15px;
                            padding: 0 10px;
                        }
                    }
                    div.date{
                        color: #868fa1;
                        font-size:11px;
                        line-height:11px;
                        text-transform: uppercase;
                    }
                }
                div.desc{
                    color: $font_color;
                    font-size: 13px;
                    line-height: 30px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    header{
                        font-family: $special_font;
                        color: $header_color;
                        margin-bottom: 20px;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        h3{
                            font-size:20px;
                            line-height: 26px;
                            @include anim;
                        }
                    }
                }
            }
        }
    }
}

.footer{
    padding: 100px 0 40px 0;
    background: $theme_color1;
    color: #637aa0;
    @media only screen and (max-width: 1200px) {
        padding: 50px 0 20px 0;
    }
    @media only screen and (max-width: 991px) {
        .container{
            > .row{
                display: flex;
                flex-flow: column;
                > div{
                    &:first-child{
                        order: 2;
                    }
                    &::last-child{
                        order: 1;
                    }
                }
            }
        }
    }
    a{
        @include anim;
        &:hover{
            color: $white !important;
        }
    }
    .container{
        > .row{
            margin: 0 -55px;
            > div{
                padding: 0 55px;
            }
        }
    }
    .footer_top{
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        @media only screen and (max-width: 1200px) {
            padding-bottom: 20px;
        }
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        > div{
            display: inline-block;
            vertical-align: top;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
            span{
                display: block;
                color: $white;
                font-size: 11.5px;
                line-height: 11px;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 10px;
            }
            &.fast_contact{
                padding-left: 55px;
                @media only screen and (max-width: 991px) {
                    padding-left: 0;
                    padding-top: 30px;
                }
                ul{
                    li{
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        a{
                            font-size: 13px;
                            color: #637aa0;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    .footer_bottom{
        padding-top: 40px;
        @media only screen and (max-width: 1200px) {
            font-size: 11.5px;
            padding-top: 20px;
            @media only screen and (max-width: 991px) {
                text-align: center;
            }
        }
        .rules{
            @media only screen and (max-width: 991px) {
                padding-bottom: 10px;
            }
            > li{
                margin-right: 55px;
                @media only screen and (max-width: 1200px) {
                    margin-right: 15px;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        a{
            color: #637aa0;
            text-transform: uppercase;
            font-weight: 700;
        }
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                font-weight: 700;
                img{
                    margin-left: 13px;
                    @media only screen and (max-width: 1200px) {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .footer_menu{
        @media only screen and (max-width: 991px) {
            text-align: center;
        }
        .fh{
            color: $white;
            text-transform: uppercase;
            margin-bottom: 25px;
            p{
                font-weight: 700;
            }
        }
        ul{
            @media only screen and (max-width: 991px) {
                margin-bottom: 30px;
            }
            li{
                a{
                    color: #637aa0;
                    font-size: 11px;
                    line-height: 23px;
                    font-weight: 700;
                }
            }
        }
    }
}

.nav_way{
    display: none;;
    padding: 18px 0;
    background-repeat: repeat-x;
    background-position: left top;
    background-size: auto 100%;
    background-image: url('./../img/nav_bg.jpg');
    @media only screen and (max-width: 991px) {
        margin-top: 71px !important;
    }
    ul{
        margin-bottom: -6px;
        li{
            display: inline-block;
            vertical-align: top;
            color: #868fa1;
            font-size: 11px;
            line-height: 11px;
            margin-right: 6px;
            position: relative;
            padding-right: 11px;
            &:last-child{
                margin-right: 0;
                div{
                    a{
                        text-decoration: underline !important;
                    }
                }
                &:after{
                    display: none;
                }
            }
            &:after{
                position: absolute;
                top: 0;
                right: 0;
                content: '-';
                display: inline-block;
            }
            div{
                a{
                    color: #868fa1;
                    font-size: 11px;
                    line-height: 11px;
                }
            }
        }
    }
}

.about{
    .about_main_img{
        padding: 26px 26px 0 26px;
        @media only screen and (max-width: 991px) {
            padding: 0;
        }
        .image{
            background: #030c22;
            height: 30vw;
            position: relative;
            @include vertical;
            @media only screen and (max-width: 991px) {
                height: 60vh;
            }
            .img{
                @include bg_default_pos;
                background-size: cover;
                @include fill;
                opacity: 0.5;
            }
        }
        header{
            text-align: center;
            font-size: 75px;
            line-height: 85px;
            display: table;
            margin: 0 auto;
            @media only screen and (max-width: 1500px) {
                font-size: 50px;
                line-height: 65px;
            }
            @media only screen and (max-width: 1200px) {
                font-size:31px;
                line-height: 46px;
            }
            @media only screen and (max-width: 991px) {
                display: block;
            }
            div{
                display: table-cell;
                vertical-align: middle;
                color: $white;
                font-weight: 700;
                font-family: $special_font;
                @media only screen and (max-width: 991px) {
                    display: block;
                }
            }
            .line_holder{
                padding: 0 33px;
                .line{
                    height: 4px;
                    width: 200px;
                    @include gradient1;
                }
            }
        }
    }
    .desc{
        > .default_font{
            color: $font_color;
        }
        .split{
            margin: 40px -40px 0 -40px;
            > div{
                padding: 0 40px;
            }
        }
        .icon_header{
            font-family: $main_font;
            @media only screen and (max-width: 991px) {
                text-align: center;
                margin-bottom: 30px;
            }
            div{
                display: inline-block;
                vertical-align: middle;
                &:first-child{
                    width: 123px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
                &:last-child{
                    width: calc(100% - 123px);
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    h2{
                        font-size: 21px;
                        line-height: 31px;
                    }
                }
            }
        }
    }
}

.subpage_header{
    font-family: $special_font;
    padding: 50px 0;
    @media only screen and (max-width: 1200px) {
        padding: 30px 0;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    span{
        color: $accent_color1;
    }
    h1{
        font-size: 43px;
        line-height: 53px;
        color: $header_color;
        @media only screen and (max-width: 1500px) {
            font-size: 31px;
            line-height: 41px;
        }
    }
}

.news{
    padding: 50px 0;
    .row{
        margin: 0 -35px;
        > div{
            padding: 0 35px;
        }
    }
    .last_news{
        article{
            min-height: 377px;
            width: 100%;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            div.last_image{
                width: 60%;
                @include bg_default_pos;
                background-size: cover;
                @include vertical;
                position: relative;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }
                .cover{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 75%;
                    height: 100%;
                    background-image: url('./../img/news_cover.png');
                    background-position: left center;

                }
            }
            div.last_desc{
                @include vertical;
                width: 40%;
                background: #f3f3f3;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                > div{
                    width: 100%;
                    padding: 0 20px;
                    @media only screen and (max-width: 1200px) {
                        padding: 0 30px;
                    }
                    @media only screen and (max-width: 991px) {
                        padding: 30px;
                    }
                }
            }
            @media only screen and (max-width: 991px) {
                width: 100%;
                padding: 0 15px;
                margin-left: 0;
            }
            div.info{
                margin-bottom: 20px;
                > div{
                    display: inline-block;
                    vertical-align: middle;
                }
                div.tag{
                    margin-right: 20px;
                    p{
                        background: $accent_color1;
                        color:$header_color;
                        display: inline-block;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size:9px;
                        line-height:15px;
                        height: 15px;
                        padding: 0 10px;
                    }
                }
                div.date{
                    color: #868fa1;
                    font-size:11px;
                    line-height:11px;
                    text-transform: uppercase;
                }
            }
            div.desc{
                color: $white;
                font-size: 13px;
                line-height: 30px;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                header{
                    font-family: $special_font;
                    color: $header_color;
                    margin-bottom: 20px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    h3{
                        font-size:24px;
                        line-height: 30px;
                        @media only screen and (max-width: 1500px) {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
    .all_news{
        padding-top: 25px;
        .news_list{
            ul{
                margin: 0 -12.5px;
                li{
                    padding: 0 12.5px;
                    width: 33.33%;
                    margin-bottom: 25px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .see_also{
        font-family: $special_font;
        margin:100px 0 50px 0;
        @media only screen and (max-width: 1200px) {
            margin: 50px 0 30px 0
        }
        @media only screen and (max-width: 991px) {
           margin: 30px 0;
           text-align: center;
        }
        h2{
            font-size: 25px;
            line-height: 25px;
        }
    }
    .news_details{
        padding-top: 50px;
        .date_header{
            font-family: $special_font;
            color: $header_color;
            margin-bottom: 60px;
            @media only screen and (max-width: 1200px) {
                margin-bottom: 30px;
            }
            div.info{
                margin-bottom: 10px;
                font-family: $main_font;
                > div{
                    display: inline-block;
                    vertical-align: middle;
                }
                div.tag{
                    margin-right: 20px;
                    p{
                        background: $accent_color1;
                        color:$header_color;
                        display: inline-block;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size:9px;
                        line-height:15px;
                        height: 15px;
                        padding: 0 10px;
                    }
                }
                div.date{
                    color: #868fa1;
                    font-size:11px;
                    line-height:11px;
                    text-transform: uppercase;
                }
            }
            h1{
                font-size: 43px;
                line-height: 54px;
                margin-bottom: 50px;
                @media only screen and (max-width: 1500px) {
                    font-size: 37px;
                    line-height: 47px;
                    margin-bottom: 30px;
                }
            }
        }
        .image{
            width: 100%;
            height: 0;
            padding-bottom: 51%;
            @include bg_default_pos;
            background-size: cover;
            margin-bottom: 50px;
            @media only screen and (max-width: 991px) {
                padding-bottom: 300px;
                margin-bottom: 30px;
            }
        }
        .desc{
            &.default_font{
                font-size: 13px;
                color: $font_color;
                margin-bottom: 50px;
            }
        }
        .banner{
            height: 12vw;
            background: #030c22;
            position: relative;
            @include vertical;
            color: $white;
            text-align: center;
            font-size: 31px;
            line-height: 41px;
            font-family: $special_font;
            padding: 0 15px;
            @media only screen and (max-width: 991px) {
                height: 300px;
            }
            p{
                width: 100%;
                position: relative;
                z-index: 2;
            }
            .image{

                padding-bottom: 0;
                @include fill;
                opacity: 0.5;
            }
        }
    }
}

.contact{
    font-size: 13px;
    line-height: 24px;
    padding-bottom: 50px;
    .subpage_header{
        h1{
            font-size: 37px;
            line-height: 47px;
            @media only screen and (max-width: 1500px) {
                font-size: 31px;
                line-height: 41px;
            }
        }
    }
    div{
        &.contact_data_box{
            padding: 35px 40px;
            @include shadow;
            border: 1px solid $border_color;
            color: $header_color;
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
                padding: 20px;
            }
            .contact_list{
                padding-top: 30px;
                ul{
                    li{
                        min-height: 21px;
                        padding-left: 36px;
                        background-position: left top;
                        background-repeat: no-repeat;
                        font-size: 13px;
                        line-height: 15px;
                        color: $header_color;
                        margin-bottom: 25px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        a{
                            color: $header_color;
                            font-weight: 700;
                        }
                        &.phone{
                            background-image:url('./../img/phone_icon.png');
                        }
                        &.mail{
                            background-image: url('./../img/mail_icon.png');
                            a{
                                text-decoration: underline !important;
                            }
                        }
                    }
                }
            }
        }
        &.contact_form{
            .default_form{
                padding: 0;
                border: none;
            }
        }
    }
}

.map{
    border-top: 1px solid $border_color;
    height: 400px;
    position: relative;
    width: 100%;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
    iframe{
        border: none;
        @include fill;
    }
}

.training{
    div.training_list{
        > ul{
            > li{
                margin-bottom: 60px;
                &.expired{
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                }
                &.active{
                    .date{
                        -webkit-box-shadow: -31px 0px 63px 10px rgba(3,12,34,0.24);
-moz-box-shadow: -31px 0px 63px 10px rgba(3,12,34,0.24);
box-shadow: -31px 0px 63px 10px rgba(3,12,34,0.24);
                    }
                    .training_box{
                        -webkit-box-shadow: 0px 0px 32px 24px rgba(3,12,34,0.24);
                        -moz-box-shadow: 0px 0px 32px 24px rgba(3,12,34,0.24);
                        box-shadow: 0px 0px 32px 24px rgba(3,12,34,0.24);
                    }
                }
            }
        }
        ul{
            li{

               > div{
                    display: inline-block;
                    vertical-align: top;
                    &.date{
                        @include anim;
                        width: 81px;
                        .date_holder{
                            width: 100%;
                            height: 0;
                            height: 81px;
                            color: $white;
                            @include gradient1;
                            @include vertical;
                            font-family: $special_font;
                           div{
                               width: 100%;
                               text-align: center;
                               span{
                                   margin-top: -2px;
                                   font-size: 31px;
                                   font-weight: 700;
                               }
                           }
                        }
                    }
                    &.training_box{
                        @include anim;
                        width: calc(100% - 81px);
                        padding: 40px;
                        border: 1px solid $border_color;
                        .info{
                            ul{
                                display: inline-block;
                                border: 1px solid $border_color;
                                li{
                                    display: inline-block;
                                    vertical-align: top;
                                    height: 35px;
                                    line-height: 35px;
                                    padding: 0 30px;
                                    font-size: 13px;
                                    color: $header_color;
                                    border-right: 1px solid $border_color;
                                    &:first-child, &:last-child{
                                        border-right: none;
                                    }
                                    p{
                                        font-weight: 700;
                                    }
                                    &:first-child{
                                        font-size: 15px;
                                        color: $white;
                                        text-transform: uppercase;
                                        font-weight: 800;
                                        font-family: $special_font;
                                        @include gradient1;
                                    }
                                }
                            }
                        }
                        .desc{
                            header{
                                font-family: $special_font;
                                font-size: 31px;
                                line-height: 41px;
                                color: $header_color;
                                margin: 40px 0;
                            }
                            font-size: 13px;
                            line-height: 30px;
                            color: $font_color;
                        }
                        .hidden_desc{
                            display: none;
                            padding-top: 40px;
                            .desc{
                                padding-bottom: 40px;
                                margin-bottom: 39px;
                                border-bottom: 1px solid $border_color;
                            }
                            .who{
                                font-size: 11px;
                                line-height: 21px;
                                color: $font_color;
                                margin-bottom: 40px;
                            }
                            .sign_in_training{
                                div{
                                    height: 55px;
                                    text-align: center;
                                    @include gradient1;
                                }
                                &:hover{
                                    .more_btn1{
                                        &:after{
                                            right: -37px;
                                        }
                                    }
                                }
                            }
                            .more_btn1{
                                line-height: 55px;
                                background-color: transparent;
                                &:hover{
                                    color: $white;
                                }
                                &:after{
                                    top: 42%;
                                    height: 26px;
                                    background-image: url('./../img/more_arr2.png');
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.find_counselor{
    position: relative;
    .form_box{
        position: relative;
        top: -43px;
        .shadow{
            -webkit-box-shadow: 0px 0px 28px 3px rgba(3,12,34,0.24);
            -moz-box-shadow: 0px 0px 28px 3px rgba(3,12,34,0.24);
            box-shadow: 0px 0px 28px 3px rgba(3,12,34,0.24);
        }
    }
    .top{
        min-height: 250px;
        @include bg_default_pos;
        background-size: cover;
        @include vertical;
    }
    .step_box{
        background: $theme_color2;
        padding-bottom: 100px;
        @media only screen and (max-width: 991px) {
            padding-bottom: 50px;
        }
       h1,h2,h3,h4,h5,h6,p{
        color: $header_color;
       }
       .section_header{
            padding: 30px 0 50px 0;
        }
       .step_list{
           ol{
            counter-reset: item;
            margin-left: 0;
            padding-left: 0;
               li{
                   display: inline-block;
                   vertical-align: top;
                   padding-left: 50px;
                   position: relative;
                   width: 17%;
                   margin-right: 10%;
                   @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    }
                   &:before{
                    -webkit-box-shadow: 0px 0px 28px 3px rgba(3,12,34,0.24);
                    -moz-box-shadow: 0px 0px 28px 3px rgba(3,12,34,0.24);
                    box-shadow: 0px 0px 28px 3px rgba(3,12,34,0.24);
                       position: absolute;
                       top: -6px;
                       left: 0;
                        display: inline-block;
                        content: counter(item) "";
                        counter-increment: item;
                        width: 34px;
                        height: 34px;
                        line-height: 34px;
                        font-size:13px;
                        font-weight: 700;
                        color: $white;
                        text-align: center;
                        border-radius: 50%;
                        background: rgba(0,0,13,1);
                        background: -moz-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                        background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(0,0,13,1)), color-stop(100%, rgba(15,56,144,1)));
                        background: -webkit-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                        background: -o-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                        background: -ms-linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                        background: linear-gradient(45deg, rgba(0,0,13,1) 0%, rgba(15,56,144,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000d', endColorstr='#0f3890', GradientType=1 );
                   }
                   header{
                       text-transform: uppercase;
                       h3{
                        font-size: 16px;
                        line-height: 25px;
                       }
                   }
                   font-size:12.5px;
                   line-height: 23px;
                   &:last-child{
                    margin-right: 0;
                   }
               }
           }
       }
    }
}

.flex_grid{
    display: flex;
    @media only screen and (max-width: 991px) {
        display: block;
    }
    > div{
        &:first-child{
            width: 70%;
            padding: 0 77px;
            @media only screen and (max-width: 1500px) {
                padding: 0 55px;
            }
            @media only screen and (max-width: 1200px) {
                padding: 0 33px;
            }
            @media only screen and (max-width: 991px) {
                padding: 0 15px;
                width: 100%;
            }
        }
        &:last-child{
            width: 30%;
            margin-top: 70px;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
    }
}

.flex_grid2{
    display: flex;
    @media only screen and (max-width: 991px) {
       flex-flow: column;
    }
    > div{
        @media only screen and (max-width: 991px) {
            align-items: stretch;
        }
        &:first-child{
            width: 70%;
            padding: 0 77px;
            @media only screen and (max-width: 1500px) {
                padding: 0 55px;
            }
            @media only screen and (max-width: 1200px) {
                padding: 0 33px;
            }
            @media only screen and (max-width: 991px) {
                padding: 0 15px;
                width: 100%;
                order: 2;
            }
        }
        &:last-child{
            width: 30%;
            margin-top: 50px;
            @media only screen and (max-width: 991px) {
               width: 100%;
               order: 1;
               margin-top: 0;
            }
        }
    }
}



.counselor_search_result{
    h3{
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .counselor_list{
        background-position: right top;
        background-repeat: no-repeat;
        background-image: url('./../img/filter_bg.jpg');
        background-size: contain;
        &.list_hidden{
            background-image: none;
            display: none;
        }
        > ul{
            margin: 0 5px;
            li{
                display: inline-block;
                vertical-align: middle;
                padding: 0 2.5px;
                width: 20%;
                margin-bottom: 5px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &:hover{
                    div.image{
                        &:before{
                            top: 5px;
                            left: 5px;
                            right: 5px;
                            bottom: 5px;
                            opacity: 1;
                        }
                        div.desc{
                            color: $accent_color1;
                        }
                    }

                }
                div.image{
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    @include bg_default_pos;
                    background-size: cover;
                    position: relative;
                    @include anim;
                    &:before{
                        content: '';
                        position: absolute;
                        top: 0x;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        border: 2px solid $white;
                        @include anim;
                        opacity: 0;
                    }
                    .desc{
                        position: absolute;
                        padding: 15px;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: auto;
                        text-shadow: 1px 1px 1px #6b6b6b;
                        font-size: 17px;
                        line-height: 16px;
                        color: $white;
                        font-family: $special_font;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @include anim;
                    }
                    .from{
                        padding: 0 10px;
                        position: absolute;
                        top: 10px;
                        left: 10px;

                        font-size: 11px;
                        line-height: 22px;
                        height: 22px;
                        font-weight: 700;
                        color: #ffffff;
                        text-transform: uppercase;
                        &:after{
                            content: '';
                            @include fill;
                            filter: blur(9px);
                        }

                        p{
                            position: relative;
                            z-index: 2;
                            font-weight: inherit;
                        }
                    }
                }
            }
        }
        .counselor_top{
            font-size: 18px;
            font-family: $special_font;
            color: $header_color;


            .filters{
                li{
                    display: inline-block;
                    vertical-align: top;
                }
            }
            form{
                > ul{
                    > li{
                        display: inline-block;
                        vertical-align: middle;
                        &:first-child{
                            width: 89px;
                        }
                        &:last-child{
                            width: calc(100% - 89px);
                            padding-left: 22px;
                        }
                        @media only screen and (max-width: 991px) {
                            margin-right: 0;
                            width: 100%;
                            &:first-child{
                                text-align: center;
                                margin-bottom: 15px;
                            }
                        }
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .find_map{
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100%;
        background-image: url('./../img/map.png');
    }
    .more{
        width: 70%;
        padding: 0 77px;
        @media only screen and (max-width: 1500px) {
            padding: 0 55px;
        }
        @media only screen and (max-width: 1200px) {
            padding: 0 33px;
        }
        @media only screen and (max-width: 991px) {
            padding: 0 15px;
            width: 100%;
        }
        > div{
            margin-bottom: 50px;
            p{
                cursor: pointer;
            }
        }
    }

    .counselor_details{
        .social{
            text-align: center;
            padding:0 0 20px 0;
            ul{
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 15px;
                    @include anim;
                    &:hover{
                        opacity: 0.7;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
        .counselor_box{
            color: $white;
            //@include gradient2;
            background: rgba(10,52,143,1);
            background: -moz-linear-gradient(45deg, rgba(10,52,143,1) 0%, rgba(10,52,143,1) 60%, rgba(25,69,179,1) 100%);
            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(10,52,143,1)), color-stop(60%, rgba(10,52,143,1)), color-stop(100%, rgba(25,69,179,1)));
            background: -webkit-linear-gradient(45deg, rgba(10,52,143,1) 0%, rgba(10,52,143,1) 60%, rgba(25,69,179,1) 100%);
            background: -o-linear-gradient(45deg, rgba(10,52,143,1) 0%, rgba(10,52,143,1) 60%, rgba(25,69,179,1) 100%);
            background: -ms-linear-gradient(45deg, rgba(10,52,143,1) 0%, rgba(10,52,143,1) 60%, rgba(25,69,179,1) 100%);
            background: linear-gradient(45deg, rgba(10,52,143,1) 0%, rgba(10,52,143,1) 60%, rgba(25,69,179,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a348f', endColorstr='#1945b3', GradientType=1 );
            padding:30px 30px 40px 80px;
            @include shadow;
            margin-bottom: 50px;
            @media only screen and (max-width: 1200px) {
                padding: 30px;
            }
            @media only screen and (max-width: 991px) {
                padding: 30px 15px;
            }
            .row{
                margin: 0 -25px;
                @media only screen and (max-width: 1200px) {
                    display: flex;
                    flex-flow: column;
                    > div{
                        align-items: stretch;
                        &.col-lg-5{
                            order: 1;
                        }
                        &.col-lg-7{
                            order: 2;
                        }
                    }
                }
                > div{
                    padding: 0 25px;
                }
            }
            .image{
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                @include bg_default_pos;
                background-size: cover;
                margin-bottom: 20px;
            }
            .contact_me{

                padding-right: 20px;
                .more_btn1{
                    @include gradient1;
                    width: 100%;
                    text-align: center;
                    &:hover{
                        color: $white;
                    }
                    &:after{
                        background-image: url('./../img/more_arr3.png');
                        height: 22px;
                    }
                }
            }
            .desc{
                font-size: 15px;
                line-height: 27px;
                header{
                    font-size: 11px;
                    line-height: 21px;
                    color: #808fb0;
                    h2{
                        color: $white;
                        margin-bottom: 10px;
                    }
                    p{
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                }
                .contact_box{
                    padding: 30px 0 50px 0;
                   ul{
                       li{
                        font-size: 17px;
                        margin-bottom: 15px;
                        @media only screen and (max-width: 991px) {
                            text-align: center;
                        }
                        p{
                            background-repeat: no-repeat;
                            background-position: left top;


                            &.phone{
                                background-image: url('./../img/c_icon1.png');
                                padding-left: 35px;
                                @media only screen and (max-width: 991px) {
                                    background-position: center top;
                                    padding-left: 0 !important;
                                    padding-top: 35px !important;
                                    text-align: center;
                                }
                            }
                            &.mail{
                                background-image: url('./../img/c_icon2.png');
                                padding-left: 35px;
                                @media only screen and (max-width: 991px) {
                                    background-position: center top;
                                    padding-left: 0 !important;
                                    padding-top: 35px !important;
                                    text-align: center;
                                }
                            }
                            &.web{
                                background-image: url('./../img/c_icon3.png');
                                padding-left: 35px;
                                @media only screen and (max-width: 991px) {
                                    background-position: center top;
                                    padding-left: 0 !important;
                                    padding-top: 35px !important;
                                    text-align: center;
                                }
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                       }
                   }
                    a{
                        color: $accent_color1;
                        text-decoration: underline !important;

                    }
                }
            }
            .featuress{
                @media only screen and (max-width: 991px) {
                    padding-top: 0 !important;
                }
                ul{
                    li{
                        display: inline-block;
                        vertical-align: top;
                        width: 48%;
                        margin-right: 4%;
                        margin-bottom: 4%;
                        &:nth-child(2n), &:last-child{
                            margin-right: 0;
                        }
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 15px;
                            text-align: center;
                        }
                        p{
                            font-size: 13px;
                            text-transform: uppercase;
                            display: block;
                            background: #091e4b;
                            color: $white;
                            height: 57px;
                            line-height: 57px;
                            position: relative;
                            width: 100%;
                            padding: 0 30px;
                            z-index: 2;
                            font-weight: 500;
                            @media only screen and (max-width: 991px) {
                                height: auto;
                                line-height: 20px;
                                padding: 15px;
                            }
                            @media only screen and (min-width: 991px) {
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                            }
                            &:hover{
                                &:after{
                                    top: 0;
                                    margin-top: 0;
                                }
                            }
                            &:after{
                                z-index: -1;
                                content: '';
                                background: $theme_color1;
                                position: absolute;
                                top: 100%;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                @include gradient1;
                                margin-top: -2px;
                                @include anim;
                                @media only screen and (max-width: 991px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.toggle_search{
    position: fixed;
    bottom: 0px;
    right: -466px;
    width: 466px;
    background: rgba(219,190,59,1);
    background: -moz-linear-gradient(left, rgba(219,190,59,1) 0%, rgba(219,190,59,1) 43%, rgba(240,204,59,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(219,190,59,1)), color-stop(43%, rgba(219,190,59,1)), color-stop(100%, rgba(240,204,59,1)));
    background: -webkit-linear-gradient(left, rgba(219,190,59,1) 0%, rgba(219,190,59,1) 43%, rgba(240,204,59,1) 100%);
    background: -o-linear-gradient(left, rgba(219,190,59,1) 0%, rgba(219,190,59,1) 43%, rgba(240,204,59,1) 100%);
    background: -ms-linear-gradient(left, rgba(219,190,59,1) 0%, rgba(219,190,59,1) 43%, rgba(240,204,59,1) 100%);
    background: linear-gradient(to right, rgba(219,190,59,1) 0%, rgba(219,190,59,1) 43%, rgba(240,204,59,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dbbe3b', endColorstr='#f0cc3b', GradientType=1 );
    padding: 27px 55px;
    z-index: 1300;
    @include anim;
    @media only screen and (max-width: 991px) {
        display: none;
    }
    &:before{
        content: '';
        height: 3px;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        @include gradient1;
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }
    &.open{
        right: 0;
        .toggle_btn{
            background-image: url('./../img/toggle_search_btn2.png')!important;
        }
    }
    .toggle_btn{
        display: inline-block;
        position: absolute;
        top: -17px;
        left: 0;
        margin-left: -87px;
        cursor: pointer;
        height: 109px;
        width: 109px;
        @include bg_default_pos;
        background-image: url('./../img/toggle_search_btn.png');
        @include anim;
    }
    .bg{
        @include fill;
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('./../img/toggle_map.png');
        z-index: 2;
    }
    .toggle_desc{
        position: relative;
        z-index: 3;
        header{
            font-family: $special_font;
            font-size: 31px;
            line-height: 41px;
            color: #061d51;
            margin-bottom: 20px;
            p{
                font-weight: 700;
            }
        }
        .desc{
            font-size:13px;
            line-height: 24px;
            color: #041437;
            margin-bottom: 20px;
        }
        .more_btn1{
            @include gradient2;
            &:after{
                background-image: url('./../img/more_arr3.png');
                height: 20px;
            }
        }
    }

}

#osm-map_institutions_map{
    height: 0 !important;
    padding-bottom: 100% !important;
}



.search_box_holder{
    position: relative;
    z-index: 1100;
    .citieslistbox_0, .citieslistbox_1, .citieslistbox_2, .citieslistbox_4{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100% !important;
        background: $white;
        height: 324px !important;
        border-left: 1px solid $border_color;
        border-right: 1px solid $border_color;
        border-bottom: 1px solid $border_color;
        .citieslist{
            > li{
                border-bottom: 1px solid $border_color;
                padding: 15px;
                @include anim;
                &:last-child{
                    border-bottom: none;
                }
                &:hover{
                    background: #eeeeee;
                }
                a{
                    text-decoration: none !important;
                    span{
                        display: block;
                        &.value{
                            .cityName{
                                font-size: 20px;
                                line-height: 30px;
                                font-family: $special_font;
                                color: $header_color;
                                font-weight: 700;
                            }
                            .powiat{
                                font-size: 13px;
                                line-height: 20px;
                                color:$font_color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.holder{
    .chosen-container{
        width: 100%;
    }
}

.multi_list{
    padding-top: 15px;
    line-height: 25px;
    .glyphicon{
        color: $accent_color1;
        cursor: pointer;
    }
}

.cateogry_top{
    li{
        display: inline-block;
        vertical-align: middle;
        p{
            &.cat_btn{
                cursor: pointer;
                margin-left: 10px;
                background: $header_color;
                color: $white;
                padding: 5px 15px;
            }
        }
    }
}
/*
.cat_box{
    @include shadow;
    background: $white;
    padding: 30px 30px 15px 30px;
    ul{
        margin:0 -15px;
        li{
            display: inline-block;
            vertical-align: top;
            width: 25%;
            padding: 0 15px;
            margin-bottom: 15px;
            @media only screen and (max-width: 991px) {
                width: 100%;
                text-align: center;
            }
            a{
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                &.main_btn1{
                    padding: 15px 30px;
                    background: $header_color;
                    color: $white;
                    display: block;
                    font-size: 18px;
                    font-weight: 700;
                    @media only screen and (max-width: 991px) {
                        font-size: 10px;
                        padding: 10px 20px;
                    }
                }
                &.main_btn2{
                    padding: 15px 30px;
                    background: $header_color;
                    color: $white;
                    display: block;
                    font-size: 15px;
                    font-weight: 700;
                    @media only screen and (max-width: 991px) {
                        font-size: 10px;
                        padding: 10px 20px;
                    }
                }
                &.main_btn3{
                    padding: 15px 30px;
                    background: $header_color;
                    color: $white;
                    display: block;
                    font-size: 13px;
                    @media only screen and (max-width: 991px) {
                        font-size: 10px;
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
}
*/

.cat_box{
    @include shadow;
    background: $white;
    padding: 30px 30px 15px 30px;
    ul{
        margin:0 -1px;
        li{
            display: inline-block;
            vertical-align: top;
            padding: 0 1px;
            margin-bottom: 10px;
            @media only screen and (max-width: 991px) {
                width: 100%;
                text-align: center;
            }
            a{
                border-radius: 50px;
                cursor: pointer;
                &.main_btn1{
                    padding:5px 15px;
                    background: $header_color;
                    color: $white;
                    display: block;
                    font-size: 11px;
                    font-weight: 700;
                    @media only screen and (max-width: 991px) {
                        font-size: 10px;
                        padding: 10px 20px;
                    }
                }
                &.main_btn2{
                    padding: 10px;
                    background: $header_color;
                    color: $white;
                    display: block;
                    font-size: 9px;
                    font-weight: 700;
                    @media only screen and (max-width: 991px) {
                        font-size: 10px;
                        padding: 10px 20px;
                    }
                }
                &.main_btn3{
                    font-weight: 700;
                    padding: 10px;
                    background: $header_color;
                    color: $white;
                    display: block;
                    font-size: 9px;
                    @media only screen and (max-width: 991px) {
                        font-size: 10px;
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
}

.activefilter{
    background: rgba(180,157,53,1) !important;
    background: -moz-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%) !important;
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(180,157,53,1)), color-stop(35%, rgba(180,157,53,1)), color-stop(100%, rgba(234,195,53,1))) !important;
    background: -webkit-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%) !important;
    background: -o-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%) !important;
    background: -ms-linear-gradient(left, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%) !important;
    background: linear-gradient(to right, rgba(180,157,53,1) 0%, rgba(180,157,53,1) 35%, rgba(234,195,53,1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b49d35', endColorstr='#eac335', GradientType=1 ) !important;
    color: $header_color !important;
}

.map_holder{
    position: relative;
    .search_box_holder{
        width: 85%;
        bottom: 17px;
        left: 17px;
        position: absolute;
        @media only screen and (max-width: 991px) {
            left: 0;
            bottom: auto;
            position: relative;
            width: 100%;
        }
    }
    #map{
        width: 100%;
        position: relative;
        padding-bottom: 130%;
        @media only screen and (max-width: 991px) {
            padding-bottom: 280px;
        }
    }
}

.or{
    padding: 30px 0;
    font-size: 20px;
    line-height: 30px;
    @media only screen and (max-width: 1200px) {
        font-size: 15px;
        line-height: 25px;
    }
}
.go_to{

    a{
        @include gradient1;
        width: 100%;
        color: $header_color;
       &:after{
        background-image: url(./../img/more_arr2.png);
        height: 23px;
        @media only screen and (max-width: 991px) {
            display: none;
        }
       }
        &:hover{
            color: $header_color;
        }
    }
}

.text-eclipsis-popup {
    position:fixed;
    background-color:#f6f7f9;
    z-index:100;
    padding:10px;
    border-radius: 3px;
    font-size:14px;
    border:1px solid #000;
    color:#000;
    line-height:18px;

    a {
        color:#000;
    }
}

.counselor_list{
    .subpage_header{
        h1{
            font-size: 18px;
            line-height: 28px;
        }
    }
}

.small_search{
    padding: 30px 0;
    .search {
        input.search_submit{
            padding-bottom: 40px;
        }
    }
}

.error{
    padding-top: 150px;
    padding-bottom: 30px;
    &.err_404{
        .error_image{
            background-image: url('./../img/404.png');
        }
    }
    &.err_500{
        .error_image{
            padding-bottom: 28%;
            background-image: url('./../img/500.png');
        }
    }
    .more_btn1{
        &:after{
            right: auto;
            left:-17px;
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
        }
    }
    .desc{
        position: relative;
        z-index: 2;
    }
    .error_image{
        margin-top: -25px;
        width: 100%;
        height: 0;
        padding-bottom: 56%;
        @include bg_default_pos;
        background-size: 100% auto;
    }
    h1{
        @media only screen and (max-width: 1200px) {
            font-size: 27px;
            line-height: 37px;
        }
    }
}

.rules{
    > div{
        display: inline-block;
        vertical-align: top;
        &.check_holder{
            width: 30px;
            .checkbox_image_box{
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                border: 1px solid $border_color;
                > div{
                    @include fill;
                    border: none;
                    &.yes{

                        &.on{
                            background: url('./../img/check_icon.png');
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
            }
        }
        &.check_desc{
            width: calc(100% - 30px);
            padding-left: 15px;
            padding-top: 5px;
            a{
                color: $accent_color1;
                text-decoration: underline  ;
            }
        }
    }
}

.popup1{
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.8);
    z-index: 9999;
   > div{
       @include fill;
       @include vertical;
   }
    .popup_box{
        padding: 0px 30px 30px 30px;
        width: 100%;
        background: $white;
        border: 1px solid $accent_color1;
        position: relative;
        .close_btn{
            position: absolute;
            top: 15px;
            right: 15px;
            font-weight: 900;
            cursor: pointer;
            color: $accent_color2;
            font-size: 30px;
            line-height: 30px;
            cursor: pointer;
        }
        header{
            padding-bottom: 30px;
            h3{
                color: $header_color;
            }
        }
        .desc{
            color: $font_color;
            height: 300px;
            overflow-y: scroll;
            padding-right: 30px;
        }
    }
    ::-webkit-scrollbar {
        width: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $accent_color1;
        cursor: pointer;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        opacity: 0.8;
      }
}

.btn-floating{
    display: inline-block;
    font-size: 13px !important;
    line-height: 50px !important;
    padding: 0 20px 0 60px !important;
    background-repeat: no-repeat;
    background-position: left 20px center;
    color: #ffffff;
    border-radius: 5px !important;
    text-align: left;
    font-weight: 600;
    background-color: #3b5998;
    background-image: url('./../img/facebook.png');
    @include anim;
    &:hover{
        transform: scale(0.95);
        color: #ffffff;
    }
}
